(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:Sports
   *
   * @description
   *
   */
  angular
    .module('neo.services')
    .factory('Sports', Sports);

  function Sports($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/public/sports');
  }
}());
